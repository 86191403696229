import { ApolloClient, createHttpLink, from, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import fetch from "isomorphic-fetch";

const httpLink = createHttpLink({
  uri: process.env.GATSBY_GRAPHQL_ENDPOINT_URI,
  fetch,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN}`,
    },
  };
});

// Contentful should be quite stable, so any error can be expected to
// be resolved by simply retrying the operation
//
// https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
// https://www.apollographql.com/docs/react/api/link/introduction/#additive-composition
const retryLink = new RetryLink({
  delay: {
    max: Infinity, // taken from official documentation
    jitter: true, // randomize retry waiting times
  },
  attempts: {
    max: 3, // max 3 attempts (including the initial)
    retryIf: (error: any) => !!error,
  },
});

export const client = new ApolloClient({
  link: from([retryLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});
