import { useEffect, useRef } from "react";

function createRoot(id: string) {
  const root = document.createElement("div");
  root.setAttribute("id", id);

  return root;
}

function addRoot(element: HTMLDivElement) {
  document.body.insertBefore(element, document.body.lastElementChild!.nextElementSibling);
}

export function usePortal(id: string) {
  const rootRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    const existingRoot = document.querySelector(`#${id}`);
    const root = existingRoot || createRoot(id);

    if (!existingRoot) {
      addRoot(root as HTMLDivElement);
    }

    root.appendChild(rootRef.current as HTMLDivElement);

    return () => {
      rootRef.current?.remove();
      !root.childNodes.length && root.remove();
    };
  }, [id]);

  function getRoot(): HTMLDivElement | undefined {
    if (typeof window === "undefined") {
      return;
    }
    if (!rootRef.current) {
      rootRef.current = document.createElement("div");
    }
    return rootRef.current;
  }

  return getRoot();
}
