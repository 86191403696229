import React, { AllHTMLAttributes } from "react";
import styled, { Breakpoints, CSSProp } from "styled-components";

import { up } from "..";
import theme from "../../../theme";

export type GridItemSize = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export const GRID_GAP_DESKTOP: CSSProp = `${theme.spacing.fn(3)}px`;
export const GRID_GAP_MOBILE: CSSProp = `${theme.spacing.fn(1.75)}px`;

interface GridProps {
  maxWidth?: keyof Breakpoints;
  testid?: string;
}

const GridBase: React.FC<GridProps & AllHTMLAttributes<HTMLDivElement>> = ({ className, children, testid }) => (
  <div className={className} data-testid={testid}>
    {children}
  </div>
);

export const Grid = styled(GridBase)`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: ${GRID_GAP_MOBILE};

  max-width: ${({ maxWidth }) => (maxWidth ? `${theme.breakpoints[maxWidth]}px` : `${theme.breakpoints.xl}px`)};

  ${up("md")} {
    grid-gap: ${GRID_GAP_DESKTOP};
  }
`;

export interface GridItemProps {
  xs?: GridItemSize;
  sm?: GridItemSize;
  md?: GridItemSize;
  lg?: GridItemSize;
  justification?: "end";
  alignment?: "center" | "end" | "auto";
}

// unfortunately prettier destroys any formatting of the following
// block, that's why we're ignoring it
// prettier-ignore
export const GridItem = styled.div<GridItemProps>`
  grid-column-start: span ${({ xs }) => xs || 12};
  ${({ alignment }) => alignment && `align-self: ${alignment};`}
  ${({ justification }) => justification && `justify-self: ${justification};`}

  ${({ sm }) => sm && `${up('sm')} { grid-column-start: span ${sm}}`}
  ${({ md }) => md && `${up('md')} { grid-column-start: span ${md}}`}
  ${({ lg }) => lg && `${up("lg")} { grid-column-start: span ${lg}}`}
`;
