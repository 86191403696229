import React from "react";
import styled from "styled-components";

interface LoadingCircleProps {
  className?: string;
}

const LoadingCircleBase = ({ className }: LoadingCircleProps) => {
  return (
    <div className={className}>
      <svg className="spinner" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="40" cy="40" r="40" fill="black" />
        <path
          className="path"
          d="M33.8111 25.0523C41.9911 21.7097 51.332 25.6311 54.6746 33.8111C58.0172 41.991 54.0957 51.3319 45.9158 54.6745C37.7358 58.0171 28.3949 54.0957 25.0523 45.9157C23.621 42.4131 23.5216 38.6976 24.5181 35.3313"
          stroke="white"
          strokeWidth="2"
        />
      </svg>
    </div>
  );
};

export const LoadingCircle = styled(LoadingCircleBase)`
  width: 80px;
  height: 80px;

  // cherry picked animation from https://mui.com/material-ui/react-progress/ "Circle indeterminate"
  .path {
    animation: transform-circle 1.5s ease-in-out infinite;
  }

  @keyframes transform-circle {
    0% {
      stroke-dasharray: 1px, 200px;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -15px;
    }
    100% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -125px;
    }
  }

  .spinner {
    animation: spin 1.5s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
