import { Script } from "gatsby";
import React from "react";
import { PageLoadingState } from "../src/components/page-loading-state/page-loading-state";
import { injectCMPEventListenerScript, injectCMScript } from "../src/utils/consent/consent-manager-script";
import { CMPGlobalStyles } from "../src/components/cookie-settings/cookie-settings";
import { CMP_ID } from "../src/utils/consent/constants";

export const WrapRootElement = () => {
  return (
    <>
      <Script>
        {`
          ${injectCMScript(CMP_ID)}
          ${injectCMPEventListenerScript()}
        `}
      </Script>
      <CMPGlobalStyles />
      <PageLoadingState />
    </>
  );
};
