import * as React from "react";
import { useEffect } from "react";

// Small helper to aid integration testing.
// - Sets attribute "[data-loaded='true']" to body.
// - Can be waited for, for tests that require hydration
//   phase to have passed.
export const IntegrationTestUtil = () => {
  useEffect(() => {
    // Run after hydration
    const timer = setTimeout(() => {
      document.body.dataset.hydrated = "true";
    });

    return () => clearTimeout(timer);
  }, []);

  return <></>;
};
