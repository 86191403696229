import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
  firebaseConfig?: any;
}

/**
 * Noop replacement for the {@link ADACirebaseAppProvider}
 * Will be replaced with ADACFirebaseAppProvider for app builds
 * App builds require a valid GATSBY_FIREBASE_CONFIG.
 *
 * See gatsby-node.ts.onCreateWebpackConfig for replacement step.
 *
 * @param children
 * @constructor
 */
export const ADACFirebaseProvider = ({ children }: Props): JSX.Element => {
  return <>{children}</>;
};
