exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-app-manage-tag-subscriptions-tsx": () => import("./../../../src/pages/app/manage-tag-subscriptions.tsx" /* webpackChunkName: "component---src-pages-app-manage-tag-subscriptions-tsx" */),
  "component---src-pages-app-my-news-tsx": () => import("./../../../src/pages/app/my-news.tsx" /* webpackChunkName: "component---src-pages-app-my-news-tsx" */),
  "component---src-pages-app-push-notification-settings-tsx": () => import("./../../../src/pages/app/push-notification-settings.tsx" /* webpackChunkName: "component---src-pages-app-push-notification-settings-tsx" */),
  "component---src-pages-not-supported-browser-tsx": () => import("./../../../src/pages/not-supported-browser.tsx" /* webpackChunkName: "component---src-pages-not-supported-browser-tsx" */),
  "component---src-templates-channel-club-tsx": () => import("./../../../src/templates/channel-club.tsx" /* webpackChunkName: "component---src-templates-channel-club-tsx" */),
  "component---src-templates-channel-download-portal-tsx": () => import("./../../../src/templates/channel-download-portal.tsx" /* webpackChunkName: "component---src-templates-channel-download-portal-tsx" */),
  "component---src-templates-channel-driver-tsx": () => import("./../../../src/templates/channel-driver.tsx" /* webpackChunkName: "component---src-templates-channel-driver-tsx" */),
  "component---src-templates-channel-driver-vehicle-team-overview-tsx": () => import("./../../../src/templates/channel-driver-vehicle-team-overview.tsx" /* webpackChunkName: "component---src-templates-channel-driver-vehicle-team-overview-tsx" */),
  "component---src-templates-channel-home-tsx": () => import("./../../../src/templates/channel-home.tsx" /* webpackChunkName: "component---src-templates-channel-home-tsx" */),
  "component---src-templates-channel-overview-tsx": () => import("./../../../src/templates/channel-overview.tsx" /* webpackChunkName: "component---src-templates-channel-overview-tsx" */),
  "component---src-templates-channel-race-calendar-tsx": () => import("./../../../src/templates/channel-race-calendar.tsx" /* webpackChunkName: "component---src-templates-channel-race-calendar-tsx" */),
  "component---src-templates-channel-rankings-tsx": () => import("./../../../src/templates/channel-rankings.tsx" /* webpackChunkName: "component---src-templates-channel-rankings-tsx" */),
  "component---src-templates-channel-team-tsx": () => import("./../../../src/templates/channel-team.tsx" /* webpackChunkName: "component---src-templates-channel-team-tsx" */),
  "component---src-templates-channel-vehicle-tsx": () => import("./../../../src/templates/channel-vehicle.tsx" /* webpackChunkName: "component---src-templates-channel-vehicle-tsx" */),
  "component---src-templates-contact-form-tsx": () => import("./../../../src/templates/contact-form.tsx" /* webpackChunkName: "component---src-templates-contact-form-tsx" */),
  "component---src-templates-global-home-tsx": () => import("./../../../src/templates/global-home.tsx" /* webpackChunkName: "component---src-templates-global-home-tsx" */),
  "component---src-templates-media-media-overview-tsx": () => import("./../../../src/templates/media/media-overview.tsx" /* webpackChunkName: "component---src-templates-media-media-overview-tsx" */),
  "component---src-templates-media-media-tsx": () => import("./../../../src/templates/media/media.tsx" /* webpackChunkName: "component---src-templates-media-media-tsx" */),
  "component---src-templates-news-article-tsx": () => import("./../../../src/templates/news-article.tsx" /* webpackChunkName: "component---src-templates-news-article-tsx" */),
  "component---src-templates-news-channel-news-tsx": () => import("./../../../src/templates/news/channel-news.tsx" /* webpackChunkName: "component---src-templates-news-channel-news-tsx" */),
  "component---src-templates-news-global-news-tsx": () => import("./../../../src/templates/news/global-news.tsx" /* webpackChunkName: "component---src-templates-news-global-news-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-race-details-tsx": () => import("./../../../src/templates/race-details.tsx" /* webpackChunkName: "component---src-templates-race-details-tsx" */),
  "component---src-templates-search-tsx": () => import("./../../../src/templates/search.tsx" /* webpackChunkName: "component---src-templates-search-tsx" */),
  "component---src-templates-simplified-home-tsx": () => import("./../../../src/templates/simplified-home.tsx" /* webpackChunkName: "component---src-templates-simplified-home-tsx" */)
}

