import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMounted } from "../../hooks";
import { LoadingCircle } from "./loading-circle";

interface PageLoadingStateProps {
  className?: string;
}

type Eventtype = "onRouteUpdate" | "onPreRouteUpdate" | "onRouteUpdateDelayed";

export const NavigationStateListener = {
  handler: (_: Eventtype) => {
    /** noop */
  },

  triggerEvent(event: Eventtype) {
    NavigationStateListener.handler(event);
  },
};

const PageLoadingStateBase = ({ className }: PageLoadingStateProps) => {
  const [active, setActive] = useState(false);
  const mounted = useMounted();

  useEffect(() => {
    NavigationStateListener.handler = (event) => {
      switch (event) {
        case "onRouteUpdateDelayed":
          setActive(true);
          break;
        case "onPreRouteUpdate":
          // noop: do nothing here,
          // since there should not be a loading state during fast transitions
          break;
        case "onRouteUpdate":
          setTimeout(() => setActive(false));
          break;
      }
    };
  }, [active]);

  const style = {
    display: mounted ? undefined : "none",
  };

  return (
    <div className={`${className} ${active && "is-active"}`} style={style}>
      <LoadingCircle />
    </div>
  );
};

export const PageLoadingState = styled(PageLoadingStateBase)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 203;
  pointer-events: none;
  opacity: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.2);

  transition: opacity 0.5s;

  &.is-active {
    opacity: 1;
    pointer-events: all;
  }
`;
