import { ChannelConfig, envVars } from "./env-vars";
import { encodePathSegment } from "./path-helper";

export const getDefaultDomain = () => envVars.CHANNEL_DOMAINS.default.domain!;

export const getRootDomain = () =>
  envVars.CHANNEL_DOMAINS[getRootChannel()!]?.domain ?? envVars.CHANNEL_DOMAINS.default.domain;

export const getSiteUrl = () => `https://${getRootDomain()}`;

export const isRootDomain = (domain?: string | null) => {
  return existsRootChannel() && getChannelConfig({ domain: getRootDomain() })?.domain === domain;
};

export const existsRootDomain = () => existsRootChannel();

export const getChannelConfig = ({ channel, channelSlug, domain }: Partial<ChannelConfig>) => {
  if (channel && envVars.INCLUDED_CHANNELS.includes(channel)) {
    return envVars.CHANNEL_DOMAINS[getRootChannel()!];
  }

  return (
    Object.values(envVars.CHANNEL_DOMAINS).find((config) => config.channel === channel) ??
    Object.values(envVars.CHANNEL_DOMAINS).find((config) => config.channelSlug === channelSlug) ??
    Object.values(envVars.CHANNEL_DOMAINS).find(
      (config) => config.domain?.replace("www.", "") === domain?.replace("www.", "")
    )
  );
};

export const getRootChannel = () => envVars.ROOT_CHANNEL;
export const isRootChannel = (channel?: string) => existsRootChannel() && envVars.ROOT_CHANNEL === channel;
export const isRootChannelBuildAtRoot = () =>
  Boolean(existsRootChannel() && getChannelConfig({ channel: getRootChannel() })?.buildAtRoot);
export const existsRootChannel = () => Boolean(envVars.ROOT_CHANNEL);

export const getDomainForChannel = (channel?: string) => {
  return getChannelConfig({ channel })?.domain ?? getDefaultDomain();
};

export const getRootChannelSlug = () => (existsRootChannel() ? encodePathSegment(envVars.ROOT_CHANNEL) : "");
export const isRootChannelSlug = (slug?: string) => {
  return Object.values(envVars.CHANNEL_DOMAINS).some((config) => config.channelSlug === slug);
};

export const isExcludedChannel = (channel?: string | null) => !!channel && envVars.EXCLUDED_CHANNELS?.includes(channel);
