import { DefaultTheme } from "styled-components";
import { breakpoints, fonts, spacing, zIndices } from "./shared";

export const motorsportTheme: DefaultTheme = {
  name: "motorsport",
  breakpoints,
  fonts,
  spacing,
  zIndices,
  header: {
    showHome: true,
    showServiceLinks: true,
    showTopTopics: true,
    position: "absolute",
    showSearchButton: true,
    languageSwitchPosition: "serviceBar",
    surface: "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)",
    fallback: "#fff",
    color: "#fff",
    logoPosition: "right",
    mouseOver: {
      surface: "rgba(0,0,0, .8)",
      color: "#fff",
    },
    megaMenu: {
      surface: "#fff",
      color: "#000",
      secondary: "rgba(0,0,0,0.4)",
      linkPrefix: null,
    },
  },
  mobileMenu: {
    size: "default",
    surface: "#fff",
    color: "#000",
    active: {
      surface: "#fff",
      color: "#000",
    },
    action: {
      surface: "rgba(0, 0, 0, 0.05)",
      color: "#000",
    },
    toggle: {
      surface: "rgba(255, 204, 0, 1)",
      color: "#000",
      active: {
        surface: "rgba(255, 204, 0, 1)",
        color: "#000",
      },
    },
  },
  footer: {
    surface: "#000",
    color: "#fff",
  },
  topTopics: {
    surface: "#000",
    color: "#fff",
    borderColor: "rgba(255, 255, 255, 0.2)",
  },
  palette: {
    action: {
      disabled: "rgba(0,0,0,0.5)",
      hover: {
        primary: "rgba(255, 219, 75, 1)",
        grey: "rgba(175, 175, 175, 0.1)",
        red: "rgba(204, 51, 51, 0.85)",
      },
    },
    common: {
      black: {
        main: "rgba(0, 0, 0, 1)",
        light: "rgba(0, 0, 0, 0.6)",
      },
      grey: {
        lightest: "rgba(247, 247, 247, 1)",
        light: "rgba(0, 0, 0, 0.05)",
        main: "rgba(175, 175, 175, 1)",
        dark: "rgba(51, 51, 51, 1)",
      },
      white: {
        main: "rgba(255, 255, 255, 1)",
        dark: "rgba(255, 255, 255, 0.6)",
      },
      divider: {
        main: "rgba(0,0,0,0.1)",
        light: "rgba(255, 255, 255, 0.2)",
      },
      red: {
        main: "rgba(204, 51, 51, 1)",
        light: "rgba(204, 51, 51, 0.05)",
        lighter: "rgba(204, 51, 51, 0.1)",
      },
    },
    ghost: {
      color: "rgba(0, 0, 0, 1)",
      surface: "transparent",
      hover: {
        color: "rgba(0, 0, 0, 1)",
        surface: "rgba(255, 204, 0, 1)",
      },
    },
    primary: {
      main: "rgba(255, 204, 0, 1)",
      color: "rgba(0, 0, 0, 1)",
    },
    secondary: {
      surface: "rgba(0,0,0,0.1)",
      color: "rgba(0, 0, 0, 1)",
    },
    tertiary: {
      surface: "#fff",
      color: "#000",
    },
    highlighted: {
      surface: "rgba(0, 0, 0, 1)",
      color: "#fff",
    },
  },
};
