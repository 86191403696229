import { DefaultTheme } from "styled-components";
import { centralEuropeanRallyTheme } from "./central-european-rally";
import { stiftungsTheme } from "./stiftung";
import { motorsportTheme } from "./motorsport";
import { getRootChannel } from "../utils/root-channel-helper";

export { motorsportTheme } from "./motorsport";
export { stiftungsTheme } from "./stiftung";
export { centralEuropeanRallyTheme } from "./central-european-rally";

export const channelThemeMap = {
  "ADAC Stiftung Sport": stiftungsTheme,
  "Central European Rally": centralEuropeanRallyTheme,
};

export const getTheme = (channel: string = "*"): DefaultTheme => {
  channel = channel ?? getRootChannel();
  return channelThemeMap[channel] ?? motorsportTheme;
};

export default motorsportTheme;
