export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1600,
};

export const fonts = {
  family: "Milo ADAC, Helvetica, Arial, Futura, Roboto, sans-serif",
  sizes: {
    h1: 40,
    h1Mobile: 36,
    h2: 24,
    big: 20,
    normal: 16,
    appNormal: 19,
    small: 14,
    smallest: 12,
  },
  weights: {
    regular: 400,
    medium: 500,
    bold: 700,
    bolder: 900,
  },
  styles: {
    normal: "normal",
    italic: "italic",
  },
};

export const spacing = {
  default: 8,
  fn: (scale = 1) => scale * spacing.default,
};

export const zIndices = {
  header: 200,
  menu: 201,
};
