import React from "react";
import { GatsbyBrowser } from "gatsby";
import { IntlProvider } from "react-intl";
import flatten from "flat";
import { navigate } from "gatsby";
import AppRootElementWrapper from "./src/utils/root-element-wrapper/root-element-wrapper";
import { getUserAgentRegexes } from "browserslist-useragent-regexp";
import { ADACFirebaseProvider } from "./src/utils/firebase/ADACFirebaseProvider";
import { ApolloProvider } from "@apollo/client";
import { client } from "./src/apollo/client";

import { IntegrationTestUtil } from "./src/utils/integration-test-util";
import { PageContext } from "./src/types/gatsby";
import { getDefaultTimezone } from "./src/utils/dafault-timezone";
import { NavigationStateListener } from "./src/components/page-loading-state/page-loading-state";
import { WrapRootElement } from "./gatsby/gatsby-shared";
import { envVars } from "./src/utils/env-vars";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => (
  <>
    <WrapRootElement />
    <ApolloProvider client={client}>
      <AppRootElementWrapper>{element}</AppRootElementWrapper>
    </ApolloProvider>
  </>
);

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

export const wrapPageElement: GatsbyBrowser<void, PageContext>["wrapPageElement"] = ({
  element,
  props: {
    pageContext: { messageOverwrites, node_locale = envVars.DEFAULT_LOCALE },
  },
}) => {
  const firebaseConfig = process.env.GATSBY_FIREBASE_CONFIG && JSON.parse(process.env.GATSBY_FIREBASE_CONFIG);
  let [language] = node_locale.split("-");
  const messages = require(`./static/i18n/${language}.json`);
  let combinedMessages = flatten({ ...messages, ...messageOverwrites }) as any;

  return (
    <>
      <ADACFirebaseProvider firebaseConfig={firebaseConfig}>
        <IntlProvider
          locale={language!}
          messages={combinedMessages}
          defaultLocale={language}
          timeZone={getDefaultTimezone()}
        >
          <>{element}</>
        </IntlProvider>
      </ADACFirebaseProvider>

      <IntegrationTestUtil />
    </>
  );
};

/**
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll
 *
 * Btw: I would've thought getSavedScrollPosition should solve this with plain gatsby.
 * But atm (2021/01/05) this does not quite yield the expected scroll-position (even
 * after applying correct usage as documented here: https://github.com/gatsbyjs/gatsby/issues/23842)
 */
export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({ routerProps: { location } }) => {
  if (!isNaN(location.state?.scrollTop)) {
    // Execute on next tick to wait for initial hydration to have been run.
    setTimeout(() => window.scrollTo(0, location.state.scrollTop || 0));

    return false;
  }

  return true;
};

const browsers = [
  // Defaults includes the most widely used browsers with their latest 2 versions, see:
  // https://github.com/browserslist/browserslist#best-practices
  // Seems safe to use (no IE or old Safari versions)...
  "defaults",

  // ...but also be more on "trusted" vendors, i.e., include older versions:
  // Build target is ES5, but to be more safe than sorry (user
  // can always dismiss dialog) based on https://caniuse.com/?search=es6
  "Chrome > 50",
  "ChromeAndroid > 50",
  "Android > 50",

  "Firefox > 53",
  "FirefoxAndroid > 53",

  "Safari > 9.1",
  "ios_saf > 11",
  "iOS > 9.3",
  "Opera > 37",

  // Edge with Chromium based variants
  "edge > 18",
];

const getLocale = (pathname: string) => {
  return envVars.SUPPORTED_LOCALES.find((locale) => {
    pathname.startsWith(`${locale.split("-").shift()}`);
  });
};

// TODO: Evaluate if suppressing page reloads due to ___webpackCompilationHash mismatches are still needed
// export const onClientEntry = () => {
//   if ("sessionStorage" in window) {
//     window.sessionStorage.setItem("gatsby-reload-compilation-hash-match", "1");
//   }
// };

export const onInitialClientRender: GatsbyBrowser["onInitialClientRender"] = () => {
  const locale = getLocale(window?.location.pathname) ?? envVars.DEFAULT_LOCALE;

  const options = { browsers, allowHigherVersions: true };
  const supportedBrowsers = getUserAgentRegexes(options);
  const isNotAllowed = [null, undefined, {}].includes(
    supportedBrowsers.find((userAgentRegex) => userAgentRegex.regex.test(navigator.userAgent))
  );

  if (!(localStorage.getItem("continueWithUnsupportedBrowser") ?? (false as boolean)) && isNotAllowed) {
    navigate("/not-supported-browser", { state: { locale } });
  }
};

export const onRouteUpdateDelayed = () => {
  NavigationStateListener.triggerEvent("onRouteUpdateDelayed");
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  NavigationStateListener.triggerEvent("onRouteUpdate");
  location.state = location.state ?? {};
  location.state.referrer = prevLocation ? prevLocation.pathname : null;
};

export const onPreRouteUpdate = () => {
  NavigationStateListener.triggerEvent("onPreRouteUpdate");

  // TODO: Evaluate if suppressing page reloads due to ___webpackCompilationHash mismatches are still needed
  // if ("sessionStorage" in window) {
  //   window.sessionStorage.setItem("gatsby-reload-compilation-hash-match", "1");
  // }
};
