//
export const CMP_ID = process.env.GATSBY_CMP_ID as string;

export const TRACKING_CONFIG_ID = process.env.GATSBY_TRACKING_CONFIG_ID as string;
export const TAG_MANAGER_ID = process.env.GATSBY_TAG_MANAGER_ID as string;
export const TAG_MANAGER_HOST = process.env.GATSBY_TAG_MANAGER_HOST as string;

export const CMP_YOUTUBE_VENDOR_ID = process.env.GATSBY_CMP_YOUTUBE_VENDOR_ID as string;
export const CMP_VIMEO_VENDOR_ID = process.env.GATSBY_CMP_VIMEO_VENDOR_ID as string;
export const CMP_TRACKING_PROVIDER_VENDOR_ID = process.env.GATSBY_CMP_TRACKING_PROVIDER_VENDOR_ID as string;

export const CMP_TICKET_PLUGIN_VENDOR_IDS = process.env.GATSBY_CMP_TICKET_PLUGIN_VENDOR_IDS?.split(",") as string[];
export const CMP_GTM_ID = process.env.GATSBY_CMP_GTM_ID?.split(",") as string[];

// see: https://support.google.com/youtube/answer/171780?hl=de#zippy=%2Cerweiterten-datenschutzmodus-aktivieren
export const CONSENT_YOUTUBE_BASE_URL = "https://www.youtube-nocookie.com";

// see: https://vimeo.zendesk.com/hc/de/articles/360001494447-Verwendung-von-Player-Parametern
// dnt=1 => do not track mode enabled
// dnt=0 => do not track mode disabled
export const CONSENT_VIMEO_DO_NO_TRACK_PARAMETER: [string, string] = ["dnt", "1"];
