import * as React from "react";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const RootElementWrapper = ({ children }: Props): JSX.Element => {
  return <>{children}</>;
};

export default RootElementWrapper;
