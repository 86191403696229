import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Breakpoints } from "styled-components";

export type BreakpointKeys = (keyof Breakpoints)[];
type BreakpointsMediaQueryList = {
  [key in keyof Breakpoints]: MediaQueryList;
};
interface BreakpointContextDefinition {
  matches?: BreakpointKeys;
}

export const BreakpointContext = React.createContext<BreakpointContextDefinition>({});

interface BreakpointContextPoviderProps {
  breakpoints: Breakpoints;
  children: ReactNode;
}

/**
 * Context Provider for setting up and returning matches
 * Relies on MediaQueryList: https://caniuse.com/?search=mediaquerylist
 * Adds onChange EventListeners, thus creating a superior version of solutions like window.resize
 * Removes onChange EventListeners once unmounting
 */
export const BreakpointContextProvider: React.FC<BreakpointContextPoviderProps> = ({ children, breakpoints }) => {
  const [matches, setMatches] = useState<BreakpointKeys>([]);
  const hasEventListener = useRef<boolean>(false);

  useEffect(() => {
    const keys: BreakpointKeys = Object.keys(breakpoints).map((key) => key as keyof Breakpoints);
    const breakpointsQueryList: BreakpointsMediaQueryList = Object.assign(
      {},
      ...keys.map((key) => ({
        [key as keyof Breakpoints]: window?.matchMedia(`(min-width: ${breakpoints[key as keyof Breakpoints]}px)`),
      }))
    );

    // Filters through all available MediaQueryLists and populates matches
    function mqlListener() {
      const matches = keys.filter((key) => breakpointsQueryList[key]?.matches);
      setMatches(matches);
    }

    // Ensure that EventListeners are only applied once
    if (!hasEventListener.current) {
      for (const key of keys) {
        try {
          // this adds a fallback solution due to inconsistencies for mediaqueryEvents in different browsers
          // the eventListener should be added across all browsers
          // reference: https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener
          breakpointsQueryList[key]?.addEventListener?.("change", mqlListener);
          if (!breakpointsQueryList[key]?.addEventListener) {
            breakpointsQueryList[key]?.addListener?.(mqlListener);
          }
        } catch (ignore) {}
      }
      hasEventListener.current = true;
      // Call once to populate matches
      mqlListener();
    }
  }, [breakpoints]);

  return <BreakpointContext.Provider value={{ matches }}>{children}</BreakpointContext.Provider>;
};
