import { Breakpoints } from "styled-components";
import theme from "../../../theme";

export const up = (breakpoint: keyof Breakpoints): string => `
    @media (min-width: ${theme.breakpoints[breakpoint]}px)
  `;

export const down = (breakpoint: keyof Breakpoints): string => `
    @media (max-width: ${theme.breakpoints[breakpoint]}px)
  `;
