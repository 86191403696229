export interface ChannelConfig {
  domain: string | null;
  channelSlug: string | null;
  buildAtRoot: boolean;
  channel: string | null;
  defaultLocale: string;
}

export const envVars = {
  get CHANNEL_DOMAINS(): Record<string | "default", ChannelConfig> {
    return {
      "ADAC Stiftung Sport": {
        domain: "www.adac-stiftungsport.de",
        channelSlug: "adac-stiftung-sport",
        buildAtRoot: true,
        channel: "ADAC Stiftung Sport",
        defaultLocale: "de-DE",
      },
      "Central European Rally": {
        domain: "www.centraleuropeanrally.eu",
        channelSlug: "central-european-rally",
        buildAtRoot: true,
        channel: "Central European Rally",
        defaultLocale: "en-US",
      },
      "ADAC GT Masters": {
        domain: "www.adac-motorsport.de",
        channelSlug: "adac-gt-masters",
        buildAtRoot: false,
        channel: "ADAC GT Masters",
        defaultLocale: "de-DE",
      },
      default: {
        domain: "www.adac-motorsport.de",
        channelSlug: null,
        buildAtRoot: false,
        channel: null,
        defaultLocale: "de-DE",
      },
    };
  },
  get SUPPORTED_LOCALES(): string[] {
    return process.env.GATSBY_SUPPORTED_LOCALES?.split(",").map((locale) => locale.trim()) ?? ["de-DE", "en-US"];
  },
  get DEFAULT_LOCALE(): string {
    return process.env.GATSBY_DEFAULT_LOCALE ?? "de-DE";
  },
  get EXCLUDED_CHANNELS(): string[] {
    return process.env.GATSBY_EXCLUDED_CHANNELS?.split(",").map((channel) => channel.trim()) ?? [];
  },
  get TOP_CHANNELS(): string[] {
    return process.env.GATSBY_TOP_CHANNELS?.split(",").map((channel) => channel.trim()) ?? [];
  },
  get INCLUDED_CHANNELS(): string[] {
    if (typeof process.env.GATSBY_INCLUDED_CHANNELS !== "string") {
      return [];
    }

    const channels = [process.env.GATSBY_ROOT_CHANNEL, ...process.env.GATSBY_INCLUDED_CHANNELS.split(",")];

    return channels.map((channel) => channel?.trim()).filter(Boolean) as string[];
  },
  get RESOLVE_404_DOMAIN(): string {
    return this.CHANNEL_DOMAINS.default.domain!;
  },
  get PATH_SEGMENT_SECURE() {
    return process.env.GATSBY_PATH_SEGMENT_SECURE ?? "secure";
  },
  get ROOT_CHANNEL() {
    return process.env.GATSBY_ROOT_CHANNEL;
  },
  get EXPORT_CALENDAR() {
    return process.env.GATBSY_EXPORT_CALENDAR === "true";
  },
};
