import { Dispatch, FormEvent, SetStateAction, useCallback, useEffect, useRef, useState } from "react";

type FieldValue = string | number | boolean | undefined;

interface FieldOptions {
  validation?: {
    onEvent?: boolean;
    required?: string;
    pattern?: {
      error: string;
      regex: RegExp;
    }[];
  };
}

interface FieldConfig<T> extends FieldOptions {
  defaultValue: T;
  error: string | undefined;
  value: T;
  validate: () => void;
}

interface FormFields {
  [key: string]: FieldConfig<any>;
}

interface FormValidity {
  [key: string]: {
    required: boolean;
    touched: boolean;
    valid: boolean;
  };
}

export interface FormValues {
  [key: string]: FieldValue;
}

/**
 * Checks form's validity
 * Checks is fields are either:
 * - not required and not touched
 * - required and valid
 * - touched and valid
 * Returns false if no @FielConfig is available
 * @param validity
 */
function formIsValid(validity: FormValidity): boolean {
  return (
    !!Object.keys(validity).length &&
    Object.values(validity).every(
      (entry) =>
        (!entry.required && !entry.touched) || (entry.required && entry.valid) || (entry.touched && entry.valid)
    )
  );
}

/**
 * Checks is value is truthy
 * @param value
 */
function valueIsValid(value: any): boolean {
  return value !== null && value !== undefined && value !== false && value !== "";
}

/**
 * Transforms @FormFields to flattended object
 * @param fields
 */
export function buildSubmitObject(fields: FormFields): FormValues {
  return Object.assign(
    {},
    ...Object.entries(fields).map((field) => ({
      [field[0]]: field[1].value,
    }))
  );
}

/**
 * Handles main form logic
 * Fields have to be registered
 * Upon submission a submitObject is being generated
 */
export function useForm() {
  const fields = useRef<FormFields>({});
  const validity = useRef<FormValidity>({});
  const [submitting, setSubmitting] = useState(false);
  const [valid, setValid] = useState(false);

  /**
   * Adds @FieldConfig to fields ref
   * @param name
   * @param defaultValue
   * @param options
   */
  function register<T>(
    name: string,
    defaultValue: T,
    options?: FieldOptions
  ): [T, Dispatch<SetStateAction<T>>, boolean, string | undefined, () => void] {
    // todo: hooks are not allowed into "normal" functions. please remove the eslint ignore and fix it

    const required = !!options?.validation?.required;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setter] = useState<T>(defaultValue);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [error, setError] = useState<string | undefined>();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [touched, setTouched] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const validate = useCallback(() => {
      const validityForKey = {
        [name]: {
          required,
          touched,
          valid: false,
        },
      };
      if (!options?.validation?.onEvent && !touched) {
        setTouched(true);
      } else if (required && !valueIsValid(value)) {
        setError(options?.validation?.required);
        validityForKey[name].valid = false;
      } else if (options?.validation?.pattern?.length) {
        const error = options.validation.pattern.find((pattern) => !pattern.regex.test(String(value)))?.error;
        setError(error);
        validityForKey[name].valid = !error;
      } else {
        setError(undefined);
        validityForKey[name].valid = true;
      }

      validity.current = {
        ...validity.current,
        ...validityForKey,
      };

      setValid(formIsValid(validity.current));
    }, [defaultValue, value, submitting, options]);

    fields.current[name] = {
      ...options,
      defaultValue,
      error,
      value,
      validate,
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      // Only trigger this if no onEvent specific validation is needed
      if (!options?.validation?.onEvent) {
        validate();
      }
    }, [defaultValue, value, submitting, options]);

    return [value, setter, required, error, validate];
  }

  function unregister(name: string) {
    delete fields.current[name];
    delete validity.current[name];

    // We lose out on the re-rendering due to useRef
    // Calling setSubmitting() function as arbitrary trigger
    setSubmitting(submitting);
  }

  const handleSubmit = (onSubmit: (values: FormValues) => Promise<void>) => async (event: FormEvent) => {
    setSubmitting(true);
    event.persist();
    event.preventDefault();

    Object.entries(fields.current).forEach((field) => field[1].validate());

    if (valid) {
      try {
        await onSubmit(buildSubmitObject(fields.current));
      } finally {
        setSubmitting(false);
      }
    }
  };

  return {
    fields,
    submitting,
    valid,
    handleSubmit,
    register,
    unregister,
  };
}
