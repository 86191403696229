import { envVars } from "./../utils/env-vars";
export type FormatMessageFunction = (props: { id: string }, values?: Record<string, string | number>) => string;

export const getDefaultLocale = (): string => envVars.DEFAULT_LOCALE ?? "de-DE";

export type Localizations = { [key: string]: string };

export interface LocalizedPage {
  contentful_id: string;
  slug?: string;
  channel?: string;
  node_locale: string;
}

export interface LocalizedPages {
  nodes: LocalizedPage[];
}
export interface HasLocalizations {
  localizations: LocalizedPages;
}
