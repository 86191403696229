import {
  getRootChannelSlug,
  getChannelConfig,
  isExcludedChannel,
  isRootDomain,
  existsRootDomain,
  isRootChannel,
  isRootChannelBuildAtRoot,
} from "./root-channel-helper";
import { getDefaultLocale } from "../types/i18n";
import slugify from "slugify";

interface LocalizePathArgs {
  channel?: string | null;
  path?: string | null;
  slug?: string | null;
  node_locale?: string;
}
const isDomainSegmentAdded = (channel?: string | null): channel is string => {
  if (!channel) {
    return false;
  }

  const channelDomain = getChannelConfig({ channel })?.domain;
  if (!isRootChannelBuildAtRoot() && isRootDomain(channelDomain)) {
    return !isRootChannel(channel || undefined);
  }

  return (
    typeof channelDomain === "string" &&
    !isRootDomain(channelDomain) &&
    (isExcludedChannel(channel) || existsRootDomain())
  );
};

const isLocaleSegmentAdded = (locale?: string | null, channel?: string | null): locale is string => {
  const channelLocale = getChannelConfig({ channel })?.defaultLocale ?? getDefaultLocale();
  return !!locale && locale !== channelLocale;
};

const isChannelSegmentAdded = (channel?: string | null): channel is string => {
  const channelConfig = getChannelConfig({ channel });
  const useChannelConfig = isRootChannel(channel ?? "*") || isExcludedChannel(channel);

  return !useChannelConfig || !channelConfig?.buildAtRoot;
};

const isValidPathSegment = (slug?: string | null): slug is string => typeof slug === "string" && slug.length > 0;

const normalizePathSegment = (pathname?: string | null) => {
  if (getRootChannelSlug() === "*" || !isRootChannelBuildAtRoot()) {
    return pathname;
  }
  return pathname?.replace(new RegExp(`^\\/?${getRootChannelSlug()}`), "");
};

export const normalizeSlug = (slug?: string | null) => {
  return slug?.replace(/[/]/g, "");
};

// Due to issue with '.'-chars preventing clear target detection for path rewrite
// see: https://github.com/simov/slugify#remove for more advanced options
export const encodePathSegment = (pathname) => {
  if (!pathname) {
    return;
  }

  return slugify(pathname?.replace(/^\//, ""), { remove: /[":.()]/g, lower: true, trim: true });
};

export const createPath = ({ channel, path, slug, node_locale }: LocalizePathArgs) => {
  let domainSegment = "";

  if (isDomainSegmentAdded(channel)) {
    domainSegment = `https://${getChannelConfig({ channel })?.domain!}`;
  }

  let languagePathSegment;
  if (isLocaleSegmentAdded(node_locale, channel)) {
    languagePathSegment = node_locale.split("-")[0];
  }

  let channelPathSegment;
  if (isChannelSegmentAdded(channel)) {
    channelPathSegment = encodePathSegment(channel!);
  }

  let pathSegment;
  if (isValidPathSegment(path)) {
    pathSegment = encodePathSegment(normalizePathSegment(path)!);
  }

  let slugPathSegment;
  if (isValidPathSegment(slug)) {
    slugPathSegment = encodePathSegment(normalizePathSegment(slug)!);
  }

  const pathname = [languagePathSegment, channelPathSegment, pathSegment, slugPathSegment].filter(Boolean).join("/");

  return `${domainSegment}/${pathname}`;
};
