import { useContext } from "react";

import { BreakpointContext } from "../../context";

/**
 * Hook to render components on certain breakpoints
 * Relies on BreakpointContextProvider
 */
export function useBreakpoints() {
  const { matches } = useContext(BreakpointContext);

  if (!matches) {
    throw new Error("BreakpointContextProvider is mandatory for useBreakpoint");
  }

  return matches;
}
