import { useEffect, useState } from "react";

/**
 * Ensures that component is mounted
 * Optionally takes delay (in ms) as an argument
 */
export function useMounted(delay?: number) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (delay) {
      setTimeout(() => setMounted(true), delay);
    } else {
      setMounted(true);
    }

    return () => setMounted(false);
  }, [delay]);

  return mounted;
}
